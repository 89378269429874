import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { RichText } from "prismic-reactjs";

import { Col, Container, Row } from "react-bootstrap";

import Anchor from "./Anchor";
import AnchorPrismic from "./AnchorPrismic";

import "../../styles/components/elements/product-list-item.scss";

const ProductListItem = ({ product }) => {
  const { url, data } = product.node;
  const {
    description,
    image,
    link,
    link_label: linkLabel,
    subtitle,
    title,
  } = data;

  return (
    <article className="product-list">
      <Container className="mw-1920" fluid>
        <Row>
          <Col className="col-xxxl-8 offset-xxxl-2">
            <div className="product-list__container">
              <figure className="product-list__media text-center py-3 px-lg-5">
                {image && (
                  <GatsbyImage
                    image={image.gatsbyImageData}
                    alt={image.alt || title || ""}
                  />
                )}
              </figure>
              <section className="product-list__content pt-4 pt-lg-0 ps-lg-5">
                <header>
                  <Anchor href={url}>
                    <h2
                      dangerouslySetInnerHTML={{ __html: title }}
                      className="h3 mb-0 text-primary"
                    />
                  </Anchor>
                  {subtitle && <p className="h3 mt-2 mb-0">{subtitle}</p>}
                </header>
                <hr className="hr-small" aria-hidden="true" />
                <RichText
                  render={description.richText}
                  serializeHyperlink={AnchorPrismic}
                />
                {link.url && linkLabel && (
                  <Anchor
                    href={link.url}
                    target={
                      link.target || (link.link_type === "Media" && "_blank")
                    }
                    className="fw-bold"
                  >
                    {linkLabel}
                  </Anchor>
                )}
              </section>
            </div>
          </Col>
        </Row>
      </Container>
    </article>
  );
};

export default ProductListItem;
